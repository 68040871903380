#msp-login .wrapper {
    display: grid;
    grid-template-columns: 44% 1fr;
    border-radius: 15px;
    overflow: hidden;
    margin: 0 0 30px;
}
#msp-login header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 44px 0 0;
    background: var(--clr-gray-150);
}
#msp-login .content-box {
    padding: 0 100px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
    background: url("/template/images/myshoppro/cover_bg.png") center no-repeat;
    color: var(--clr-white);;
    background-size: cover;
}
#msp-login .content-box > div > p {
    margin: 0 0 10px;
    font-weight: 300;
}
#msp-login .content-box > div > p > a {
    font-weight: 700;
    text-decoration: underline;
    color: var(--clr-white);
}
#msp-login .content-box > div > a {
    margin: 0 auto 20px;
}
#msp-login .content-box > div > p > a {
    font-weight: 700;
    text-decoration: underline;
    color: var(--clr-white);
}
#msp-login .content-box > p {
    font-size: 16px;
    margin: 0 0 10px;
    font-weight: 300;
    line-height: 1.5;
}
.myshop--cart-edit .cta-wrapper.vertical {
    gap: 16px;
}
.myshop--cart-edit .msp-info--attach,
.myshop--cart-edit .msp-info--notes {
    margin-top: 40px;
}
.msp-info--ambient > ul.grid-ambients.default-state li:nth-of-type(n+6) {
    display: none;
}
.msp-info--ambient > ul.grid-ambients li.ambient-add {
    display: none;
}
.msp-info--ambient > ul.grid-ambients.default-state li.ambient-add {
    display: block;
}
.pb23-btn.msp-btn--start,
.pb23-btn.msp-btn--start * {
    --button-height: 62px;
    --button-padding: 30px;
    --button-color: var(--clr-prm-600);
    --button-color--hover: var(--clr-prm-600);
    font-size: 19px;
    font-weight: 500;
}
#Line_index .pb23-header--content .content--wrapper,
#Product_lastrasProducts .pb23-header--content .content--wrapper
{
    max-width: 100%;
}

@media (min-width: 1181px) {
    #Products_index2024 .pb23-filters .pb23-submenu {
        position: absolute !important;
        padding: 15px !important;
        background: var(--clr-white) !important;
    }
}

@media (max-width: 1080px) {    
    #msp-login .content-box {
        padding: 20px;
    }
}
@media (max-width: 769px) {
    #msp-login .wrapper {
        grid-template-columns: 100%;
    }
    #msp-login header {
        padding: 20px 0 0;
    }
    #msp-login .wrapper h1 img {
        max-height: 133px;
    }
    #msp-login .content-box {
        border-radius: 10px;
        overflow: hidden;
    }
    #msp-login .content-box > div > a {
        width: 100%;
        max-width: 100%;
    }
}